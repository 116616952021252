import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import MasterCalendarPage from 'pages/MasterCalendarPage/MasterCalendarPage';
import MainContainer from './MainContainer';
import AffiliatesPage from 'pages/AffiliatesPage';

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 5px' : '15px')};
`;
function AffiliatesContainer({ match: { path } }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route path={`${path}`} exact component={AffiliatesPage} />
          <Redirect to={`${path}`} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
}
AffiliatesContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({}),
  }).isRequired,
};
export default AffiliatesContainer;
