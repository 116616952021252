import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/FormUI/Button';
import useScreenView from 'hooks/useScreenView';
import classes from './coherereferals.module.scss';
import WelcomeReferals from 'assets/welcome_referrals.png';
import styled from 'styled-components';
import { useTheme, useMediaQuery, Tooltip, TextField, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from 'assets/ic-share-white.svg';
import RevenueDetails from './Components/RevenueDetails';
import CohereAffilliateReferals from './Components/CohereAffiliateReferals';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import CohereAffiliatePayouts from './Components/CohereAffiliatePayouts';
import InviteCoachesModal from '../Modals/InviteCoachesModal/InviteCoaches.Modal';
import WelcomeCohereAffiliatesModal from '../Modals/WelcomeCohereAffiliate/WelcomeCohereAffiliates.Modal';
import WelcomeCohereAffiliates from '../Modals/WelcomeCohereAffiliate/WelcomeCohereAffiliates.Modal';
import ManageBankSettings from '../Modals/ManageBankSettingsModal/ManageBankSettings.Modal';
import { updateUser } from 'actions/update-user';
import {
  getAffiliateBalanceForPayout,
  getAffiliateReferralDetail,
  getReferralsData,
  getAffiliateReferralHistory,
  payout,
} from 'services/affiliate.service';
import {
  downloadEnrollmentdetails,
  getAffiliateCoach,
  getContributionAffiliateDataApi,
  getEnrollmentStatus,
  toggleAffiliateEnrollment,
} from 'services/affiliate.service';
import useAccount from 'hooks/useAccount';
import ConnectStripeModal from 'components/Modals/ConnectStripeModal';
import { redirectInNewTab, redirectTo } from 'services/links';
import { downloadFile } from 'utils/utils';
import { useHttp } from 'hooks/index';
import { useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin-left: 10px;
`;
export default function CohereReferalsDetailPage() {
  const { mobileView } = useScreenView();
  const { user } = useAccount();
  const [loading, setloading] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [value, setValue] = useState('Referrals');
  const [showBankSettings, setShowBankSettings] = useState(false);
  const [showWelcomModal, setShowWelcomModal] = useState(user.showFirstTimeAffiliatePopup);
  const [modalVisible, setModalVisible] = useState(false);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(true);
  const [showExport, setShowExport] = useState(false);

  const dispatch = useDispatch();
  const { request } = useHttp();

  const [referralsData, setReferralsData] = useState([]);
  const [affiliateBalanceForPayout, setAffiliateBalanceForPayout] = useState(undefined);
  const [referalManualPayout, setreferalManualPayout] = useState(false);
  const [refferalDetail, setRefferalDetail] = useState(undefined);
  const [getPaid, setGetPaid] = useState(false);
  const [affiliateData, setaffiliateData] = useState([]);
  useEffect(() => {
    setloading(true);
    getEnrollmentStatus().then(enrollmentStatus => setIsEnrolled(enrollmentStatus));
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, [setIsEnrolled]);

  useEffect(() => {
    setloading(true);
    getAffiliateReferralHistory().then(res => {
      setaffiliateData(res);
      if (res.length > 0) {
        setShowExport(true);
      } else {
        setShowExport(false);
      }
    });
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);
  useEffect(() => {
    if (getPaid === false) {
      setloading(true);
      if (referralsData?.length === 0) {
        getReferralsData().then(data => {
          if (data.manualPayoutRevenue > 0 || data.autoRevenue > 0) {
            setreferalManualPayout(true);
          }
          setReferralsData(convertToKeyValuePairs(data));
        });
      }
      getAffiliateBalanceForPayout().then(data => setAffiliateBalanceForPayout(data));

      getAffiliateReferralDetail().then(data => setRefferalDetail(data));
    }
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, [referralsData, referalManualPayout]);

  const onGetPaidStandardAccountForAffiliate = () => {
    setGetPaid(true);
    payout()
      .then(res => {
        setAffiliateBalanceForPayout({ ...affiliateBalanceForPayout, amount: 0 });
        getReferralsData().then(data => {
          if (data.manualPayoutRevenue > 0 || data.autoRevenue > 0) {
            setreferalManualPayout(true);
          }
          setReferralsData(convertToKeyValuePairs(data));
        });
      })
      .catch(err => {
        setAffiliateBalanceForPayout({ ...affiliateBalanceForPayout, amount: 0 });
        getReferralsData().then(data => {
          if (data.manualPayoutRevenue > 0 || data.autoRevenue > 0) {
            setreferalManualPayout(true);
          }
          setReferralsData(convertToKeyValuePairs(data));
        });
      });
    setTimeout(() => {
      setGetPaid(false);
    }, 10000);
  };

  function convertToKeyValuePairs(data) {
    const mappedKeysreferalManualPayout = [
      'referralsCount',
      'totalRevenue',
      'inEscrowRevenue',
      'paidOutRevenue',
      'manualPayoutRevenue',
      'autoRevenue',
    ];
    const mappedKeys = ['referralsCount', 'totalRevenue', 'inEscrowRevenue', 'paidOutRevenue'];
    const sortOrderreferalManualPayout = [
      'Coaches Referred',
      'Total Earned Revenue',
      'Affiliate Revenue Earned For Payout',
      'Available Soon for Payout',
      'Cash Paid Out',
      'Total Affiliate Revenue For Manual Payout',
    ];
    const sortOrder = [
      'Referrals / Total Invites',
      'Total Earned Revenue',
      'Available Soon for Payout',
      'Cash Paid Out',
    ];

    const resultreferalManualPayout = Object.keys(data)
      .filter(key => mappedKeysreferalManualPayout.includes(key))
      .map(key => {
        let title = key;
        let currency = null;
        let newValue = null;

        if (key === 'referralsCount') {
          title = 'Referrals / Total Invites';
          newValue = `${data.successfullyRegisteredCount}/${data.referralsCount}`;
        }
        if (key === 'totalRevenue') {
          title = 'Total Earned Revenue';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'inEscrowRevenue') {
          title = 'Available Soon for Payout';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'paidOutRevenue') {
          title = 'Cash Paid Out';
          currency = data.paidOutCurrency;
        }
        if (key === 'autoRevenue') {
          title = 'Affiliate Revenue Earned For Payout';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'manualPayoutRevenue') {
          title = 'Total Affiliate Revenue For Manual Payout';
          currency = data.availableToPayoutCurrency;
        }

        return { title: title, value: data[key], currency: currency, newValue: newValue };
      });
    const result = Object.keys(data)
      .filter(key => mappedKeys.includes(key))
      .map(key => {
        let title = key;
        let currency = null;
        let newValue = null;
        if (key === 'referralsCount') {
          title = 'Referrals / Total Invites';
          newValue = `${data.successfullyRegisteredCount}/${data.referralsCount}`;
        }
        if (key === 'totalRevenue') {
          title = 'Total Earned Revenue';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'inEscrowRevenue') {
          title = 'Available Soon for Payout';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'paidOutRevenue') {
          title = 'Cash Paid Out';
          currency = data.paidOutCurrency;
        }
        if (key === 'autoRevenue') {
          title = 'Affiliate Revenue Earned For Payout';
          currency = data.availableToPayoutCurrency;
        }
        if (key === 'manualPayoutRevenue') {
          title = 'Total Affiliate Revenue For Manual Payout';
          currency = data.availableToPayoutCurrency;
        }

        return { title: title, value: data[key], currency: currency, newValue: newValue };
      });
    if (referalManualPayout === false) {
      return result.sort((a, b) => sortOrder.indexOf(a.title) - sortOrder.indexOf(b.title));
    }

    if (referalManualPayout === true) {
      return resultreferalManualPayout.sort(
        (a, b) => sortOrderreferalManualPayout.indexOf(a.title) - sortOrderreferalManualPayout.indexOf(b.title),
      );
    }
  }

  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forAffiliateAccount=true', 'POST').then(({ link }) => {
      setStripeUrl(link);
      setModalVisible(true);
    });
  }, [request]);
  const handlerEnrollClick = () => {
    if (user?.isAffiliateCoachEnable === false) {
      getAffiliateCoach()
        .then(res => {
          setModalVisible(true);
          setStripeUrl(res);
        })
        .catch(err => {
          console.log('error occured', err.response);
          if (err.response.data === 'User already has a stripe affiliate account') {
            onVerificate();
          }
        });
    } else if (user.affiliateAccountTransfersEnabled === false) {
      onVerificate();
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseModalNow = () => {
    setModalVisible(false);
    redirectTo(stripeUrl);
  };
  const disablePopUp = async () => {
    await request('/Account/DisableAffiliateFirstTimePopup', 'POST').then(res => {
      let u = {
        ...user,
        showFirstTimeAffiliatePopup: false,
      };
      dispatch(updateUser(u));
    });
  };

  return loading === true ? (
    <div className={classes.container}>
      <Loader />
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes.buttons_container}>
        <Grid
          style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}
          container
          xs={12}
          sm={12}
          lg={12}
        >
          <Grid container xs={12} sm={12} lg={mobileView ? 12 : 4}>
            <Button
              className={classes.welcome_button}
              autoWidth
              onClick={() => {
                setShowWelcomModal(true);
              }}
            >
              Welcome to the Cohere Affiliate Program
              <StyledImg previewImg={WelcomeReferals} />
            </Button>
          </Grid>
          <div className={classes.rightbuttoncontainer}>
            <Grid style={{ display: 'flex', gap: '10px' }} container xs={12} sm={12} lg={12}>
              {user?.isAffiliateCoachEnable === false || user.affiliateAccountTransfersEnabled === false ? (
                <>
                  <div className={classes.mainContainer}>
                    {/* <div className={classes.automatedPayout}>Automated Payout Pending</div>
                <Tooltip
                  title="This folder contains resources that will make it easier to share Cohere with your friend, family and colleagues."
                  arrow
                >
                  <InfoIcon htmlColor="#C9B382" />
                </Tooltip> */}
                  </div>
                  <Button invert className={classes.welcome_button_invert} autoWidth onClick={handlerEnrollClick}>
                    Verify & Setup Payouts
                  </Button>
                </>
              ) : (
                <Button
                  invert
                  className={classes.welcome_button_invert}
                  autoWidth
                  onClick={() => {
                    setShowBankSettings(true);
                  }}
                >
                  Manage Bank Settings
                </Button>
              )}
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                <Button
                  invert
                  className={classes.welcome_button_invert}
                  autoWidth
                  onClick={() => {
                    redirectInNewTab(
                      'https://drive.google.com/drive/folders/1Ba6YF2cY5cx2hRmfsIbRcNli0t2Cwtgi?usp=share_link',
                    );
                  }}
                >
                  Affiliate Resources
                </Button>
                <Tooltip
                  title="This folder contains resources that will make it easier to share Cohere with your friends, family and colleagues."
                  arrow
                >
                  <InfoIcon htmlColor="#C9B382" />
                </Tooltip>
              </div>
              <Button
                className={classes.welcome_button}
                autoWidth
                onClick={() => {
                  setShowInviteModal(true);
                }}
              >
                <Icon src={ShareIcon} />
                Invite Coaches
              </Button>
            </Grid>
          </div>
        </Grid>
      </div>
      <RevenueDetails
        referralsData={referralsData}
        affiliateBalanceForPayout={affiliateBalanceForPayout}
        onGetPaidStandardAccountForAffiliate={onGetPaidStandardAccountForAffiliate}
        refferalDetail={refferalDetail}
        referalManualPayout={referalManualPayout}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflowX: mobileView ? 'scroll' : 'unset',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {['Referrals'].map((item, index) => {
            return (
              <div
                style={{
                  // borderBottom: item === value ? `3px solid #215C73` : 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  // alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <Button
                  variant="contained"
                  value={item}
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '16px',
                    fontWeight: value != item ? '400' : '800',
                    color: '#000000DE',
                    minWidth: 'auto',
                    fontFamily: 'Avenir',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '1rem 0px',
                    letterSpacing: '0.15px',
                  }}
                  onClick={e => {
                    if (value != item) {
                      setValue(item);
                    }
                  }}
                >
                  {item}
                </Button>
              </div>
            );
          })}
        </div>
        {showExport && (
          <Button
            onClick={async () => {
              if (value === 'Referrals') {
                await downloadEnrollmentdetails().then(res => {
                  downloadFile(res, 'AffiliateTracking.csv');
                });
              }
            }}
            invert
            autoWidth
            className={classes.export_button}
          >
            <ExportIcon width="16" height="16" />
            Export
          </Button>
        )}
      </Box>
      {value === 'Referrals' ? <CohereAffilliateReferals affiliateData={affiliateData} /> : <CohereAffiliatePayouts />}
      <InviteCoachesModal setShowInviteModal={setShowInviteModal} showInviteModal={showInviteModal} />
      <WelcomeCohereAffiliates
        isOpen={showWelcomModal}
        onSubmit={() => {
          setShowWelcomModal(false);
        }}
        onCancel={() => {
          setShowWelcomModal(false);
          disablePopUp();
        }}
        width={'700px'}
        maxWidth="700px"
        minWidth={!mobileView ? '700px' : ''}
        hiddenCancel
        disableFooter
        CrossIconHide
        ignoreDisable
      />
      <ManageBankSettings setShowBankSettings={setShowBankSettings} showBankSettings={showBankSettings} />
      {modalVisible && (
        <ConnectStripeModal
          isOpen={modalVisible}
          onCancel={handleCloseModal}
          onSubmit={handleCloseModalNow}
          mobileView={mobileView}
        />
      )}
    </div>
  );
}
