import React from 'react';
import classes from './Dropdownlist.module.scss';
import { NavLink } from 'react-router-dom';
import { colors } from 'utils/styles';
import styled from 'styled-components';
import useAccount from 'hooks/useAccount';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../constants';
import { useDispatch } from 'react-redux';
import { setShowCreateModal } from 'actions/quizes';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from 'pages/CreateContribution/ReviewAndSubmit';

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  `}

  &:hover {
    text-decoration: underline;
  }
`;

const StyledView = styled.div`
  cursor: pointer;
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  `}

  &:hover {
    text-decoration: underline;
  }
`;

const DropDownList = ({ list = [], containerClassName, itemClassName }) => {
  let containerClass = classes.container;
  let itemClass = classes.listItem;
  const { user } = useAccount();
  const dispatch = useDispatch();
  if (containerClassName) {
    containerClass = `${classes.container} ${containerClassName}`;
  }
  if (itemClassName) {
    itemClass = `${classes.listItem} ${itemClassName}`;
  }
  const { verifiedSendingAddresses } = useEmailMarketing();
  const isSendGridConnected = user?.isSendGridConnected;
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const activePaidTierPlan = useShallowEqualSelector(paidTier.activePaidTierPlanSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  return (
    <div className={containerClass}>
      {list?.map(item => {
        let isDisabled = false;
        if (
          item?.title === 'Email Campaign' &&
          (activePaidTierPlan?.isEmailCampaignEnabled === false ||
            activePaidTierPlan?.isEmailCampaignEnabled === undefined)
        ) {
          isDisabled = true;
        } else if (
          item?.title === 'Email Campaign' &&
          (!isSendGridConnected || verifiedSendingAddresses?.length === 0)
        ) {
          isDisabled = true;
        }
        return (
          <div
            className={classes.listItemBorder}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            {item?.title === 'Form/Quiz' ? (
              <StyledView
                onClick={() => {
                  if (isDisabled === false) {
                    dispatch(setShowCreateModal(true));
                  } else {
                    console.log('isDisabled==>', isDisabled);
                  }
                }}
                to={item?.to}
                className={itemClass}
                isDisabled={isDisabled}
              >
                {item?.title}
              </StyledView>
            ) : (
              <StyledLink className={itemClass} to={item?.to} isDisabled={isDisabled}>
                {item?.title}
              </StyledLink>
            )}
            <Tooltip
              title={
                item?.title === 'Email Campaign'
                  ? 'Create, schedule, and send an email campaign to your list in a few steps. Requires a Cohere Accelerate plan.'
                  : item?.title === 'Lead Magnet'
                  ? 'Collect client emails and grow your list. Create an opt-in and thank-you page, attach your video, audio, or PDF lead magnet, or create a quiz lead magnet across a few steps.'
                  : item?.title === 'Form/Quiz'
                  ? 'Create and manage customized quizzes and surveys, integrate them into your services, and gain insights with personality quizzes.'
                  : 'Contributions are services on Cohere. These include one-to-one services, workshops, group programs, self-paced courses, memberships, and communities.'
              }
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon
                style={{
                  marginRight: '30px',
                }}
                htmlColor={'#C9B382'}
              />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default DropDownList;
